<template>
  <div class="log-statistics">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="日志统计" />

    <ml-tip :showHeader="false" style="border: 0" class="clear-tip-pd-t">
      <div class="log-statistics-count">
        <div
          class="log-statistics-count-item"
          v-for="item in countData"
          :key="item"
          :style="{ background: `linear-gradient(135deg, ${item.color[0]}, ${item.color[1]})` }"
        >
          <div class="log-statistics-count-item-title">{{ item.title }}</div>
          <div class="log-statistics-count-item-value">{{ item.value }}</div>
          <div class="log-statistics-count-item-view">
            <div>近七天 {{ item.dValue }}</div>
            <div>本月 {{ item.mValue }}</div>
          </div>
        </div>
      </div>
    </ml-tip>

    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0">
      <ml-form inline labelWidth="90px" :model="searchData" ref="searchDataRef">
        <el-row :gutter="20">
          <el-col :span="6" :offset="0">
            <!-- 阿米巴 -->
            <ml-select
              clearable
              prop="companyId"
              placeholder="请选择阿米巴"
              :options="unitOptions"
              label="阿米巴:"
              keyName="companyId"
              labelName="companyName"
              valueName="companyId"
              v-model="searchData.companyId"
              @change="selectUnit"
            />
          </el-col>
          <el-col :span="6" :offset="0">
            <!-- 所属项目 -->
            <ml-select
              clearable
              prop="projectId"
              placeholder="请选所属项目"
              :options="projectData"
              label="所属项目:"
              keyName="projectId"
              labelName="projectName"
              valueName="projectId"
              v-model="searchData.projectId"
              @change="searchFn"
            />
          </el-col>
          <el-col :span="6" :offset="0">
            <!-- 人员 -->
            <ml-select
              clearable
              prop="userId"
              placeholder="请选择人员姓名"
              :options="userOption"
              label="人员姓名:"
              keyName="label"
              labelName="userName"
              valueName="userId"
              v-model="searchData.userId"
              @change="searchFn"
            />
          </el-col>

          <el-col :span="6" :offset="0">
            <ml-input
              prop="operateName"
              placeholder="请输入操作内容"
              style="margin-bottom: 0"
              label="操作内容："
              v-model="searchData.operateName"
            />
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="6" :offset="0">
            <!-- 使用端口 -->
            <ml-select
              clearable
              prop="portType"
              placeholder="请选择使用端口"
              :options="inspectionTypeData"
              label="使用端口:"
              keyName="label"
              labelName="label"
              valueName="value"
              v-model="searchData.portType"
              :style="{ 'margin-bottom': 0 }"
              @change="searchFn"
            />
          </el-col>
          <el-col :span="8">
            <!-- 日期 -->
            <ml-date-picker
              prop="date"
              startPlaceholder="开始日期"
              endPlaceholder="结束日期"
              label="操作时间:"
              type="datetimerange"
              :style="{ 'margin-bottom': 0 }"
              v-model="searchData.date"
            />
          </el-col>
          <el-col :span="4" :offset="0">
            <!-- 搜索按钮 -->
            <ml-button
              :style="{ 'margin-bottom': 0 }"
              submitText="搜索"
              submitIcon="el-icon-search"
              cancelText="重置"
              cancelIcon="el-icon-refresh"
              cancelType="danger"
              @click-cancel="resetSearchData"
              @click-submit="searchFn"
            />
          </el-col>

          <el-col :span="6" :offset="0" :style="{ textAlign: 'right' }">
            <!-- 添加巡检计划 -->
            <ml-button
              :style="{ 'margin-bottom': 0 }"
              submitText="导出"
              cancelText="发送消息"
              cancelType="primary"
              @click-cancel="clickSmes"
              @click-submit="clickExport"
            />
          </el-col>
        </el-row>
      </ml-form>
    </ml-tip>
    
    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip" style="margin-bottom: 0">
      <!-- 表格 -->
      <el-table :data="tabberData.data" style="width: 100%" :height="tabberHeight(555)">
        <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="阿米巴"
          prop="companyName"
          min-width="15%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="项目"
          prop="projectName"
          min-width="15%"
        />
        <el-table-column align="center" label="人员姓名" prop="userName" min-width="15%" />
        <!-- <el-table-column align="center" label="操作模块" prop="operateName" min-width="15%" /> -->
        <el-table-column align="center" label="使用端口" prop="logCustType" min-width="15%">
          <template #default="{ row }">
            <!-- 'PC、ANDROID、IOS' -->
            <span v-if="row.logCustType === 'PC'">PC端</span>
            <span v-if="['ANDROID', 'IOS'].includes(row.logCustType)">手机端</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="操作内容"
          prop="operateName"
          min-width="15%"
        />
        <el-table-column align="center" label="操作结果" prop="operateDesc" min-width="15%" />
        <el-table-column align="center" label="操作时间" prop="createTime" min-width="15%" />
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>

    <!-- 删除巡检计划提示框 -->
    <!-- <ml-dialog
      ref="deleteInspectPlanDialogRef"
      :content="deleteInspectPlanData.content"
      @click-submit="submitDeleteInspectPlan"
    /> -->
    <!-- 发送消息提示框 -->
    <ml-dialog
      width="600px"
      ref="smesDialogRef"
      title="发送消息"
      showClose
      @click-submit="submitSmesDialog"
    >
      <template #body>
        <ml-form
          labelWidth="120px"
          style="width: 100%"
          :model="submitSmesDialogForm"
          :rules="submitSmesDialogFormRules"
          ref="submitSmesDialogFormRef"
        >
          <ml-input
            prop="title"
            label="推送标题:"
            placeholder="请输入推送标题"
            v-model.trim="submitSmesDialogForm.title"
          />

          <ml-input
            prop="content"
            type="textarea"
            :rows="5"
            label=""
            placeholder="请输入消息内容"
            v-model.trim="submitSmesDialogForm.content"
          />

          <ml-select
            class="assignedPeople-select"
            prop="receiveCompanyId"
            placeholder="请选择组织单位"
            :options="unitOptions"
            label="组织单位:"
            keyName="companyId"
            labelName="companyName"
            valueName="companyId"
            :disabled="submitSmesDialogForm.userVos.length > 0"
            v-model="submitSmesDialogForm.receiveCompanyId"
            v-model:node="receiveCompanyIdNode"
          />

          <el-form-item label="推送对象" prop="userStatus">
            <el-radio-group v-model="submitSmesDialogForm.userStatus">
              <el-radio :label="0">全部</el-radio>
              <el-radio :label="1">指定人</el-radio>
              <el-button
                v-if="[1].includes(submitSmesDialogForm.userStatus)"
                size="mini"
                type="primary"
                style="margin-right: 20px"
                @click="clickOpenSmesPeopleDialog"
              >
                添加人员
              </el-button>
              <el-radio :label="2">其他人</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="
              submitSmesDialogForm.userVos.length > 0 &&
              [1].includes(submitSmesDialogForm.userStatus)
            "
          >
            <el-tag
              style="margin: 5px 10px"
              type="default"
              v-for="item in submitSmesDialogForm.userVos"
              :key="item"
              >{{ item.userName }}</el-tag
            >
          </el-form-item>

          <ml-input
            prop="telephones"
            type="textarea"
            :rows="3"
            label="其他用户"
            :required="submitSmesDialogForm.userStatus === 2"
            placeholder="请输入其它用户手机号（用英文逗号分开）"
            v-model.trim="submitSmesDialogForm.telephones"
          />
        </ml-form>
      </template>
    </ml-dialog>
    <!-- 添加指派人提示框 -->
    <ml-dialog
      width="1200px"
      ref="smesPeopleDialogRef"
      title="指定人"
      @clickSubmit="smesPeopleDialogSubmit"
      @clickClose="smesPeopleDialogClose"
    >
      <template #body>
        <div>
          <div>
            <ml-form
              inline
              labelWidth="80px"
              :model="smesPeopleDialogForm"
              ref="smesPeopleDialogFormRef"
            >
              <!-- 帐号 -->
              <ml-input
                prop="userCodeLike"
                placeholder="请输入账号"
                label="帐号:"
                v-model="smesPeopleDialogForm.userCodeLike"
              />
              <!-- 用户 -->
              <ml-input
                prop="userNameLike"
                placeholder="请输入用户名"
                label="用户名:"
                v-model="smesPeopleDialogForm.userNameLike"
              />
              <ml-select
                class="assignedPeople-select"
                prop="companyId"
                placeholder="请选择阿米巴"
                :options="unitOptions"
                label="阿米巴:"
                keyName="companyId"
                labelName="companyName"
                valueName="companyId"
                :style="{ marginRight: '40px' }"
                v-model="smesPeopleDialogForm.companyId"
              />
              <!-- <ml-input
                label="阿米巴:"
                disabled
                v-model="smesPeopleDialogForm.companyName"
                style="margin-right: 40px"
              /> -->
              <!-- 搜索按钮 -->
              <ml-button
                submitText="搜索"
                submitIcon="el-icon-search"
                cancelText="重置"
                cancelIcon="el-icon-refresh"
                cancelType="danger"
                @click-cancel="resetSearchDataUser"
                @click-submit="searchFnUser"
              />
            </ml-form>
          </div>
          <div class="assignedPeople-table-box">
            <el-table
              :data="tabberDataUser.data"
              style="width: 100%"
              :height="200"
              @select-all="selectAll"
              @select="select"
              :row-key="row => row.userId"
              ref="smesPeopleDialogTableRef"
            >
              <el-table-column type="selection" :reserve-selection="true" width="55" />
              <el-table-column align="center" label="账号" prop="userCode" min-width="30%" />
              <el-table-column
                show-overflow-tooltip
                align="center"
                label="用户名"
                prop="userName"
                min-width="15%"
              />
              <el-table-column align="center" label="阿米巴" prop="companyName" min-width="30%" />
              <el-table-column
                show-overflow-tooltip
                align="center"
                label="职位"
                prop="job"
                min-width="20%"
              />
            </el-table>
            <!-- 分页 -->
            <ml-pagination
              :total="tabberDataUser.total"
              :page="tabberDataUser.page"
              v-model="tabberDataUser.page"
              :size="tabberDataUser.size"
              @current-change="currentChangeUser"
              @size-change="sizeChangeUser"
            />
          </div>
        </div>
      </template>
    </ml-dialog>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import {
  tabberHeight,
  searchParams,
  getTabberData,
  getTreeString,
  getProjectsString,
  clearMemoryPageFn,
  formetData,
  mobilePhoneRegExp,
  replacePerCent
} from '@/utils'
import { useStore } from 'vuex'

export default {
  name: 'LogStatistics',
  setup() {
    const { commit, getters, dispatch } = useStore()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    const getMemoryPage = computed(() => getters.getMemoryPage)
    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      operateName:getMemoryPage.value.searchData.operateName,
      companyId: getMemoryPage.value.searchData.companyId || '',
      projectId: getMemoryPage.value.searchData.projectId || '',
      userId: getMemoryPage.value.searchData.userId || '',
      portType: getMemoryPage.value.searchData.portType || '',
      date: getMemoryPage.value.searchData.date || []
    })

    // 单位配置
    const unitOptions = ref([])
    // 所属项目配置
    const projectData = ref([])
    // 巡检类型
    const inspectionTypeData = ref([
      { label: 'PC端', value: 'PC' },
      { label: '手机端', value: 'MOBILE' }
    ])

    // 处理所属项目
    const getProjectData = async companyId => {
      const projectParams = {}
      if (isBusinessDepartment.value && companyId) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectData.value = data || []
    }

    const selectUnit = () => {
      searchData.projectId = ''
      searchFn()
    }
    watch(
      () => searchData.companyId,
      newvalue => {
        if (newvalue) {
          // 查询项目
          getProjectData(newvalue)
          // 查询人员
          getUserTabberData()
        }
      }
    )
    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      clearMemoryPageFn(commit, searchData)
      tabberData.page === 1 ? getInspectPlans() : (tabberData.page = 1)
      getloginLogCountStatistics()
      getinspectLogCountStatistics()
      geterrorLogCountStatistics()
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getInspectPlans() : (tabberData.page = 1)
      getloginLogCountStatistics()
      getinspectLogCountStatistics()
      geterrorLogCountStatistics()
    }
    /* 结束 搜索 **********************************/

    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: getMemoryPage.value.page || 1,
      size: getMemoryPage.value.size || 10
    })

    // 获取列表
    const getInspectPlans = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const { date } = searchData
      const searchDataParams = searchParams(searchData, ['date'])
      if (date.length > 0) {
        searchDataParams.startTime = formetData(date[0])
        searchDataParams.endTime = formetData(date[1])
      }
      searchDataParams.operateName = replacePerCent(searchData.operateName)
      const { data, total } = await getTabberData(
        dispatch,
        'fetchSysLogInfos',
        params,
        searchDataParams
      )
      tabberData.data = data || []
      tabberData.total = total || 0
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getInspectPlans()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }

    // 获取m账户列表
    const userOption = ref([])
    const getUserTabberData = async () => {
      const companyId = searchData.companyId
      const { data } = await dispatch('fetchGetAmoebaBelowSysUsers',companyId)
      userOption.value = data || []
    }

    /* 结束 数据列表 **********************************/
    const countData = ref([
      { title: 'PC端登录次数', value: 0, dValue: 0, mValue: 0, color: ['#519ff1', '#9cc4ef'] },
      {
        title: '移动端登录次数',
        value: 0,
        dValue: 0,
        mValue: 0,
        color: ['#f1a851', '#efc89c']
      },
      {
        title: '巡检次数',
        value: 0,
        dValue: 0,
        mValue: 0,
        color: ['#51f1aa', '#9cefcf']
      },
      {
        title: '故障上报次数',
        value: 0,
        dValue: 0,
        mValue: 0,
        color: ['#f151ae', '#ef9cd7']
      }
    ])
    // 登录次数统计
    const getloginLogCountStatistics = () => {
      const { date } = searchData
      const searchDataParams = searchParams(searchData, ['date'])
      if (date.length > 0) {
        searchDataParams.startTime = formetData(date[0])
        searchDataParams.endTime = formetData(date[1])
      }
      searchDataParams.operateName = replacePerCent(searchData.operateName)
      dispatch('fetchgetloginLogCountStatistics', searchDataParams).then(data => {
        if (data && data.code === 200) {
          const {
            mobileTotal,
            monthMobileTotal,
            monthPcTotal,
            pcTotal,
            sevenMobileTotal,
            sevenPcTotal
          } = data.data || {}
          countData.value[0].value = pcTotal || 0
          countData.value[0].dValue = sevenPcTotal || 0
          countData.value[0].mValue = monthPcTotal || 0
          countData.value[1].value = mobileTotal || 0
          countData.value[1].dValue = sevenMobileTotal || 0
          countData.value[1].mValue = monthMobileTotal || 0
        }
      })
    }
    // 巡检次数统计
    const getinspectLogCountStatistics = () => {
      const { date } = searchData
      const searchDataParams = searchParams(searchData, ['date'])
      if (date.length > 0) {
        searchDataParams.startTime = formetData(date[0])
        searchDataParams.endTime = formetData(date[1])
      }
      searchDataParams.operateName = replacePerCent(searchData.operateName)
      dispatch('fetchgetinspectLogCountStatistics', searchDataParams).then(data => {
        if (data && data.code === 200) {
          const { monthInspectTotal, inspectTotal, sevenInspectTotal } = data.data || {}
          countData.value[2].value = inspectTotal || 0
          countData.value[2].dValue = sevenInspectTotal || 0
          countData.value[2].mValue = monthInspectTotal || 0
        }
      })
    }
    // 故障上报次数统计
    const geterrorLogCountStatistics = () => {
      const { date } = searchData
      const searchDataParams = searchParams(searchData, ['date'])
      if (date.length > 0) {
        searchDataParams.startTime = formetData(date[0])
        searchDataParams.endTime = formetData(date[1])
      }
      searchDataParams.operateName = replacePerCent(searchData.operateName)
      dispatch('fetchgeterrorLogCountStatistics', searchDataParams).then(data => {
        if (data && data.code === 200) {
          const { errorTotal, monthErrorTotal, sevenErrorTotal } = data.data || {}
          countData.value[3].value = errorTotal || 0
          countData.value[3].dValue = sevenErrorTotal || 0
          countData.value[3].mValue = monthErrorTotal || 0

        }
      })
    }

    // 发送消息
    // 单位
    const receiveCompanyIdNode = ref({})
    // 消息模板
    const smesDialogRef = ref()
    // 打开发送
    const clickSmes = () => {
      submitSmesDialogForm.title = ''
      submitSmesDialogForm.templateId = ''
      submitSmesDialogForm.content = ''
      submitSmesDialogForm.telephones = ''
      submitSmesDialogForm.userStatus = 0
      submitSmesDialogForm.userVos = []
      submitSmesDialogForm.receiveCompanyId = ''
      smesDialogRef.value.showDialog = true
    }
    // 确认发送
    const submitSmesDialog = () => {
      submitSmesDialogFormRef.value.CustomFormRef.validate()
        .then(() => {
          const nParams = searchParams(submitSmesDialogForm, ['userVos'], true)
          if (submitSmesDialogForm.userStatus === 1) {
            nParams.userVos = submitSmesDialogForm.userVos
          }
          dispatch('fetchAddSmsSendRecord', nParams).then(async data => {
            if (data && data.code === 200) {
              commit('setError', {
                status: true,
                title: data.message,
                message: '正在获取新的数据...',
                type: 'success'
              })
              smesDialogRef.value.showDialog = false
            }
          })
        })
        .catch(() => {})
    }
    // 发送对话框表单
    // 选择指派人列表
    const selectData = ref([])
    const submitSmesDialogFormRef = ref()
    const submitSmesDialogForm = reactive({
      title: '',
      templateId: '',
      content: '',
      receiveCompanyId: '',
      telephones: '',
      userStatus: 0,
      userVos: []
    })
    const templateNode = ref({})
    watch(
      () => templateNode.value,
      newvalue => {
        submitSmesDialogForm.content = newvalue.content
      }
    )
    // 表单校验
    const isUserStatusOne = () => {
      const { userStatus, userVos } = submitSmesDialogForm
      if (userStatus === 1 && userVos.length <= 0) {
        return false
      } else {
        return true
      }
    }
    // 表单校验
    const isUserTelephones = () => {
      const { telephones } = submitSmesDialogForm
      if (!telephones) {
        return true
      } else {
        const telephonesArr = telephones.split(',')
        let isTrue = true
        telephonesArr.map(phone => {
          if (!mobilePhoneRegExp.test(phone)) {
            isTrue = false
          }
        })
        return isTrue
      }
    }
    const submitSmesDialogFormRules = {
      title: [{ required: true, message: '请输入推送标题 ', trigger: 'blur' }],
      content: [{ required: true, message: '请输入内容', trigger: 'blur' }],
      receiveCompanyId: [{ required: true, message: '请选择接收阿米巴 ', trigger: 'blur' }],
      userStatus: [
        { required: true, validator: isUserStatusOne, message: '请选择接收人', trigger: 'blur' }
      ],
      telephones: [
        {
          required: false,
          message: '请输入手机号',
          trigger: ['blur', 'change']
        },
        {
          validator: isUserTelephones,
          message: '请输入正确手机号',
          trigger: ['blur', 'change']
        }
      ]
    }
    watch(
      () => submitSmesDialogForm.userStatus,
      newvalue => {
        if (newvalue === 2) {
          submitSmesDialogFormRules.telephones[0].required = true
        } else {
          submitSmesDialogFormRules.telephones[0].required = false
        }
      }
    )

    // 指定人 *******************************************************************
    // 对话框
    const smesPeopleDialogRef = ref()
    const smesPeopleDialogTableRef = ref()
    // 打开对话框
    const clickOpenSmesPeopleDialog = () => {
      submitSmesDialogFormRef.value.CustomFormRef.validateField(['receiveCompanyId'])
      if (submitSmesDialogForm.receiveCompanyId) {
        if (receiveCompanyIdNode.value.companyName) {
          const { companyId } = receiveCompanyIdNode.value
          smesPeopleDialogForm.companyId = companyId
        }
        selectData.value = submitSmesDialogForm.userVos
        getAssignedPeopleData()
        smesPeopleDialogRef.value.showDialog = true
      }
    }
    // 确定对话框
    const smesPeopleDialogSubmit = () => {
      submitSmesDialogForm.userVos = selectData.value
      smesPeopleDialogRef.value.showDialog = false
      selectData.value = []
      smesPeopleDialogTableRef.value.clearSelection()
    }
    // 关闭对话框
    const smesPeopleDialogClose = () => {
      selectData.value = []
      smesPeopleDialogTableRef.value.clearSelection()
    }

    // 表单
    const smesPeopleDialogFormRef = ref()
    const smesPeopleDialogForm = reactive({
      userCodeLike: '',
      userNameLike: '',
      companyId: ''
    })

    // 表格配置
    const tabberDataUser = reactive({
      data: [],
      total: 0,
      page: 1,
      size: 10,
      isSubmie: false
    })

    // 获取账户列表
    const getAssignedPeopleData = async (
      params = { pageIndex: tabberDataUser.page, pageSize: tabberDataUser.size }
    ) => {
      const searchDataParams = searchParams(smesPeopleDialogForm, ['companyName'])
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetAppUsers',
        params,
        searchDataParams
      )
      tabberDataUser.data = data || []
      tabberDataUser.total = total || 0
      if (selectData.value.length > 0) {
        selectData.value.map(item => {
          smesPeopleDialogTableRef.value.toggleRowSelection(item, true)
        })
      }
    }

    //分页事件
    watch([() => tabberDataUser.page, () => tabberDataUser.size], () => {
      getAssignedPeopleData()
    })
    const currentChangeUser = page => {
      tabberDataUser.page = page
    }
    const sizeChangeUser = size => {
      tabberDataUser.size = size
    }

    // 重置搜索内容
    const resetSearchDataUser = () => {
      smesPeopleDialogFormRef.value.CustomFormRef.resetFields()
      tabberDataUser.page === 1 ? getAssignedPeopleData() : (tabberDataUser.page = 1)
    }
    // 搜索
    const searchFnUser = () => {
      tabberDataUser.page === 1 ? getAssignedPeopleData() : (tabberDataUser.page = 1)
    }

    // 选中的添加到列表
    const getSelection = selection =>
      selection.map(item => {
        return {
          userId: item.userId,
          userName: item.userName,
          telephone: item.telephone
        }
      })
    // 选中单个
    const select = selection => {
      selectData.value = getSelection(selection)
    }
    // 选中全部
    const selectAll = selection => {
      selectData.value = selectData.value = getSelection(selection)
    }

    // 打开详情
    const smesDetailsDialogRef = ref()
    const smesDetailsDialogData = reactive({
      title: '',
      content: '',
      createTime: '',
      userStatus: 0,
      userVos: []
    })
    const descriptionsList = computed(() => [
      {
        label: '消息标题:',
        value: smesDetailsDialogData.title
      },
      {
        label: '推送对象:',
        value:
          smesDetailsDialogData.userStatus === '0'
            ? '全部'
            : `指定人员（${smesDetailsDialogData.userVos.map(item => item.userName).join()}）`
      },
      {
        label: '推送时间:',
        value: smesDetailsDialogData.createTime
      },
      {
        label: '消息内容:',
        value: smesDetailsDialogData.content
      }
    ])
    const clickView = async (index, row) => {
      const data = await dispatch('fetchGetSmsSendRecord', row.recordId)
      if (data && data.code === 200) {
        Object.keys(smesDetailsDialogData).map(item => {
          smesDetailsDialogData[item] = data.data[item]
        })
      }
      smesDetailsDialogRef.value.showDialog = true
    }
  
    // 导出
    const jsonFields = reactive({
      阿米巴: 'companyName',
      项目: 'projectName',
      人员姓名: 'userName',
      使用端口: 'logCustType',
      操作内容: 'operateName'
    })

    // 确定
    const { export_json_to_excel } = require('../../utils/excel/Export2Excel')
    const formatJson = (filterVal, jsonData) => jsonData.map(v => filterVal.map(j => v[j]))
    const clickExport = () => {
      //导出的方法
      require.ensure([], () => {
        const tHeader = []
        // 上面设置Excel的表格第一行的标题
        const filterVal = []

        Object.keys(jsonFields).map(item => {
          tHeader.push(item)
          filterVal.push(jsonFields[item])
        })
        // 上面的index、nickName、name是tableData里对象的属性
        const list = tabberData.data //把data里的tableData存到list
        const data = formatJson(filterVal, list)
        export_json_to_excel(tHeader, data, '列表excel')
      })
    }

    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {
        noCompanyCode: 'business_department'
      })
      unitOptions.value = data || []

      getInspectPlans()
      getloginLogCountStatistics()
      getinspectLogCountStatistics()
      geterrorLogCountStatistics()
    })
    return {
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      tabberData,
      currentChange,
      sizeChange,
      tabberHeight,
      unitOptions,
      getTreeString,
      getProjectsString,
      selectUnit,
      projectData,
      inspectionTypeData,
      countData,
      userOption,
      submitSmesDialogForm,
      submitSmesDialogFormRef,
      submitSmesDialogFormRules,
      clickSmes,
      smesDialogRef,
      submitSmesDialog,
      receiveCompanyIdNode,
      smesPeopleDialogRef,
      smesPeopleDialogSubmit,
      smesPeopleDialogClose,
      clickOpenSmesPeopleDialog,
      smesPeopleDialogForm,
      smesPeopleDialogFormRef,
      currentChangeUser,
      tabberDataUser,
      sizeChangeUser,
      resetSearchDataUser,
      searchFnUser,
      select,
      selectAll,
      smesPeopleDialogTableRef,
      templateNode,
      smesDetailsDialogRef,
      clickView,
      descriptionsList,
      clickExport
    }
  }
}
</script>

<style lang="scss" scoped>
.log-statistics {
  @extend %params-global;

  .clear-tip-pd-t{
    padding-top:20px;
  }

  .el-input__inner {
    width: 170px;
  }
  &-count {
    height: 155px;
    display: flex;
    &-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // border: 1px solid #d8d8d8;
      color: #fff;
      margin: 0 10px;
      padding: 10px;
      border-radius: 5px;
      // color: #666;
      &-value {
        font-size: 24px;
        font-weight: 700;
      }
      &-view {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
</style>
